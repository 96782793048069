// Quasar SCSS (& Sass) Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the Sass/SCSS variables found in Quasar's source Sass/SCSS files.

// Check documentation for full list of Quasar variables
$font-main: "Roboto", "PT Sans", "Helvetica", "Arial", sans-serif;
$font-second: Verdana, Geneva, sans-serif, sans-serif;
$font-pro: Montserrat, Arial, Helvetica, sans-serif;

// Your own variables (that are declared here) and Quasar's own
// ones will be available out of the box in your .vue/.scss/.sass files
$site-width: 1200px;

$site-width-lg: 1200px;
$site-width-xl: 1200px;
$site-width-md: 1020px;
$site-width-sm: 100%;
$site-width-xs: 100%;

//$site-width-sm: 400px;
//$site-width-md: 800px;
//$site-width-lg: 1200px;
$left-col-width: 225px;
$logo-width: 235px;

// It's highly recommended to change the default colors
// to match your app's branding.
// Tip: Use the "Theme Builder" on Quasar's documentation website.

$primary   : #1976D2;
$primary   : #1279b9;
$secondary : #26A69A;
$accent    : #9C27B0;

$dark      : #1D1D1D;
$white      : #fff;

$positive  : #21BA45;
$negative  : #C10015;
$info      : #31CCEC;
$warning   : #F2C037;

$my-grey: #e8e8e8;
$my-red: #cc0024;
//$my-dark: #3f3f3f;
$my-dark: #777777;


//=================
$at: 0.4s;
$fat: 0.15s;
//=================
@import "mixins";
